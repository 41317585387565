.maxWidth {
  max-width: 107.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;

  &.work {
    padding: 0;
  }
}

.medium {
  max-width: 53.125rem;
}

.small {
  max-width: 46rem;
  margin: 0 auto;
}

@media screen and (max-width: 62rem) {
  .maxWidth,
  .small {
    margin: 0 1rem;
    padding: 0;
  }

  .medium {
    margin: 0 auto;
  }

  .overflowImage {
    margin: 0;
  }

  .mobileFullWidth {
    max-width: none;
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 55rem) {
  .medium {
    margin: 0 1rem;
  }
}
