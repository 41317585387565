.layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3.75rem;
  box-sizing: content-box;

  &--withOverflow {
    overflow: hidden;
  }
}

.children {
  position: relative;

  &::after {
    content: '';
    border-top: 1px solid var(--border);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 107.5rem;
    width: calc(100% - 2.5rem * 2);
    margin: 0 auto;
  }

  &--white::after {
    border-color: var(--white);
  }
}

.mock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.6875rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  padding-top: 1.25rem;
  color: var(--white);

  * {
    white-space: nowrap;
  }

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  svg {
    transform: rotate(180deg);
  }
}

.errorWrapper {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.headerSpacer {
  height: 1rem;
}

.header {
  z-index: 10;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background);
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.nav {
  color: var(--text);

  > a:not(:last-child),
  > div {
    margin-right: 1.75em;
  }
}

.icon {
  position: absolute;
  top: 0;
  right: -8%;
  padding-left: 0.1875rem;
  padding-bottom: 0.1875rem;
  transform: translateY(-34%);
  background-color: var(--background);
  font-size: 0;

  &--durov {
    background: none;

    svg {
      height: 1.375rem;
      background-color: var(--background);

      path {
        fill: var(--text);
        animation: durovFadeInOut 1.5s linear infinite;
      }
    }
  }
}

@keyframes durovFadeInOut {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }
}

a.freeDurov {
  color: var(--text) !important;
  border-color: var(--border-auth);
}

.burger {
  display: none;
}

.search {
  margin-right: 1.75rem;
  width: 1rem;
  height: 1rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.logo {
  display: flex;
}

.search,
.logo {
  path:first-child {
    fill: var(--text);
  }
}

.footerWrapper {
  margin-top: auto;
}

.footer {
  background-color: var(--dark);
  color: var(--white);
}

.logoFooter {
  display: block;

  svg {
    display: block;
    width: 6rem;
    height: auto;
  }
}

.scName {
  margin: 0 0 0.625rem;
  color: var(--neutralTransparent);

  span {
    position: relative;
    top: 0.0625rem;
  }
}

.scLinks {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 0.75rem;
}

.footerGrid {
  display: grid;
  gap: 3.125rem 5vw;
  grid-template-columns: 1.95fr 1fr 1fr 1fr;
  grid-template-areas:
    'logoFooter navFooter1 navFooter2 footerControls'
    'sc navFooter1 navFooter2 footerControls'
    'copyright copyright hosting support';
}

.logoFooter {
  grid-area: logoFooter;
}

.navFooter1 {
  grid-area: navFooter1;
}

.navFooter2 {
  grid-area: navFooter2;
}

.sc {
  grid-area: sc;
  margin-top: 0.625rem;
}

.footerControls {
  grid-area: footerControls;
}

.support {
  position: relative;
  grid-area: support;
  max-width: 10rem;
}

.hosting {
  grid-area: hosting;
  display: flex;
  flex-direction: column;

  span {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
}

.copyright {
  grid-area: copyright;
  align-self: flex-end;
}

.footerNav {
  a {
    display: block;
    margin-bottom: 0.5rem;
    line-height: 1.375;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.iconRow {
  display: flex;
  gap: 0 0.75rem;

  &:not(:last-child) {
    margin-bottom: 2.15rem;
  }
}

.iconRowSingle {
  align-items: center;
}

.footerSelect {
  border: none;
  color: inherit;
  background: none;
  padding: 0;
  text-indent: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
}

.option {
  background: var(--background);
  color: var(--text);
}

.iconRowMessage {
  margin: 0;
}

.modalClose {
  display: none;
}

.container {
  height: initial;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.overlay {
  padding: 0;
  background: transparent;
  z-index: 100;
}

.modal {
  background-color: var(--background);
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  box-shadow: none;
  z-index: 101;

  .header {
    position: static;
  }

  .nav {
    display: block;
  }
}

.root {
  transition: 0.2s ease-in-out;
}

.rootClosing {
  opacity: 0;
}

.activeLink {
  color: var(--blue4);
}

.longread {
  button {
    padding: 0 0 0.38rem 0;
    color: var(--text);
  }

  path {
    stroke: var(--text);
  }

  ul {
    min-width: 6.625rem;
  }
}

.authBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  pointer-events: none;
  min-width: 8.125rem;
}

.loaded {
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 84rem) {
  .footerGrid {
    display: grid;
    gap: 3.125rem 5vw;
    grid-template-columns: 1.95fr 1fr 1fr 1fr;
    grid-template-areas:
      'logoFooter navFooter1 navFooter2 footerControls'
      'sc navFooter1 navFooter2 footerControls'
      'copyright hosting support support';
  }
}

@media screen and (max-width: 64rem) {
  .nav {
    a:not(:last-child) {
      margin-right: 1.125em;
    }
  }
}

@media screen and (max-width: 64rem) {
  .nav {
    a {
      font-size: 0.8125rem;

      &:not(:last-child) {
        margin-right: 1.125em;
      }

      .icon--durov svg {
        height: 1.125rem;
      }
    }
  }

  .longread {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 62rem) {
  .layout {
    padding: 0;
    margin-top: 6.875rem;

    &--special-mobile {
      margin-top: 3.875rem;
    }
  }

  .children {
    &::after {
      display: none;
    }
  }
}

@media screen and (max-width: 60rem) {
  .authBox {
    min-width: 0;
    opacity: 1;
    pointer-events: all;
  }

  .logo {
    svg {
      width: 11.25rem;
      height: auto;
    }
  }

  .headerSpacer {
    display: none;
  }

  .header {
    transition: 0s;
    transition-delay: 0.2s;
    background-color: transparent;
    pointer-events: none;
  }

  .desktopNav {
    display: none !important;
  }

  .search {
    order: 1;
  }

  .logo {
    order: 2;

    svg {
      width: 9.375rem;
      height: auto;
    }
  }

  .burger {
    border: none;
    height: 1.25rem;
    width: 1.25rem;
    background: none;
    order: 3;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      transform-origin: 50% 50%;
      transition: 0.2s ease-in-out;

      rect {
        fill: var(--text);
      }

      &:first-child {
        margin-bottom: 0.1875rem;
      }
    }
  }

  .burgerOpen {
    svg {
      &:first-child {
        transform: rotate3d(0, 0, 1, 45deg) translate3d(0, 0.2656rem, 0);
      }

      &:last-child {
        transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -0.2656rem, 0);
      }
    }
  }

  .headerContent {
    pointer-events: initial;
    background-color: var(--background);
  }

  .headerRow {
    padding: 1.25rem 0;
  }

  .nav {
    display: none;
    background-color: var(--background);
    padding: 1rem 1rem 0;

    a {
      display: block;
      font-size: 1.25rem;
      line-height: 1.75;
      font-weight: 600;
      width: calc(50% + 4.6875rem);
      text-align: right;
      padding: 0.8125rem 0 1.625rem;

      &:not(:last-child) {
        margin: 0 0 0.8125rem;
        border-bottom: 0.0625rem solid var(--neutralLight);
      }
    }
  }

  .dropDownMobile {
    width: calc(50% + 4.6875rem);
    margin: 0 0 0.8125rem;
    padding-bottom: 1.62rem;
    text-align: right;
    border-bottom: 0.0625rem solid var(--neutralLight);

    .dropDownButton {
      width: 100%;
      padding: 0.8125rem 0 0;
      justify-content: flex-end;
      font-size: 1.25rem;
      line-height: 1.75;
      font-weight: 600;
      border: none;
      border-radius: 0;
    }

    :global(.accordion__container) {
      position: static;
    }

    .dropDownList {
      padding: 0;
      padding-top: 1.62rem;
      box-shadow: none;
      border: none;
      background-color: transparent;
    }

    .dropDownItem {
      padding: 0;
      margin-bottom: 1.25rem;

      &:last-child {
        margin-bottom: 0;
        background-color: transparent;
      }
    }

    .dropDownLink {
      width: 100%;
      padding: 0;
      font-weight: 400;
      line-height: 1.4;
      font-size: 1.125rem;
    }

    & .icon {
      position: static;
      padding: 0;
      margin-right: 0.37rem;
      transform: none;
    }
  }

  .longread {
    border-color: var(--neutralLight);
  }

  .auth {
    display: none;
  }
}

a.linkWithIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--blue4);

  .icon {
    position: static;
    padding: 0;
    margin-right: 0.37rem;
    transform: none;
    margin-left: auto;
  }
}

@media screen and (max-width: 49rem) {
  .footerGrid {
    grid-template-columns: 1fr 1fr;
    display: grid;
    gap: 1rem 1.75rem;
    grid-template-areas:
      'logoFooter logoFooter'
      'navFooter1 navFooter2'
      'sc sc'
      'footerControls footerControls'
      'copyright copyright'
      'hosting support';
  }

  .footer {
    position: relative;
  }

  .copyright {
    margin-bottom: 0.875rem;
  }

  .navFooter1,
  .navFooter2,
  .sc,
  .footerControls {
    padding-bottom: 2.5rem;
  }

  .logoFooter {
    padding-bottom: 4rem;
  }

  .iconRow {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

.modal {
  .navBox {
    padding: 10vh 0;
    overflow: auto;
    max-height: calc(100vh - 3.75rem);
  }

  .headerRow {
    flex-shrink: 0;
  }

  .modalNav {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
  }
}
