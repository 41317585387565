.nav {
  display: flex;
  border-top: 4px solid var(--border);
  max-width: 107.5rem;
  margin: 0 auto;
}

.negative {
  border-top: 1px solid var(--white);
  border-bottom: 1px solid var(--white);
  display: flex;
  flex-direction: column;
}

.mobile,
.footer {
  border: none;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.mobile {
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 1.875rem;
}

.footer {
  gap: 1rem;
}
