.aside {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 3.75rem;
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &--mobile {
    display: none;
  }
}

.asideLogo {
  display: block;
  width: 100%;
  height: auto;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 3.75rem;
  height: 3.75rem;
  border: none;
  background-color: var(--aside-bg);
  flex-shrink: 0;
  cursor: pointer;
}

.soc {
  list-style: none;
  padding: 1.25rem 0 1.1875rem;
  margin: 0;
  background: var(--aside);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3125rem;

  @supports (-webkit-backdrop-filter: blur(0)) {
    padding-bottom: 1.0938rem;
  }
}

.socItem {
  height: 1.875rem;
  width: 1.875rem;
}

.socLink {
  display: block;
  color: var(--text);

  svg {
    display: block;
  }
}

.content {
  padding: 0.9375rem;
  background-color: var(--search);
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 2.5rem;
  box-sizing: border-box;
  color: var(--white);
  letter-spacing: 0.005em;
  text-transform: uppercase;

  &--empty {
    background-color: var(--aside);
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data {
  margin: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 0.6875rem;

  span {
    font-weight: 700;
  }
}

.number {
  width: 1.625rem;
  height: 1.625rem;
  margin-top: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--aside-number);
  font-size: 0.75rem;
  font-weight: 500;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.25rem;
}

.title {
  margin: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 0.6875rem;
}

.line {
  border: 1px solid var(--white);
  width: 0.9375rem;
}

.asideLogo {
  width: 1.5rem;
  color: var(--white);
}

.content__inner {
  flex: 1 1 calc(100% - 21.25rem);
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  text-align: end;
}

@media (max-width: 62rem) {
  .aside {
    display: none;

    &--mobile {
      position: fixed;
      z-index: 3;
      top: 3.75rem;
      height: 3.125rem;
      width: 100%;
      padding: 0.625rem 0.9375rem;
      display: flex;
      flex-direction: block;
      background-color: var(--search);
      box-sizing: border-box;
      color: var(--white);
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-in;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &--toTop {
        transform: translate3d(0, -6.875rem, 0);
      }
    }
  }

  .content {
    padding: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1.25rem;
  }

  .title,
  .data {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 0.625rem;
    line-height: 1;
  }

  .line {
    transform: rotate(90deg);
  }

  .number {
    margin: 0;
  }
}
