.rate {
  margin: 0;
  font-size: 0.75rem;
}

.symbol {
  font-weight: 700;
  text-transform: uppercase;
}

.up {
  color: #369964;

  path {
    stroke: #369964;
  }
}

.down {
  color: red;

  svg {
    transform: rotate(180deg);
  }

  path {
    stroke: red;
  }
}
