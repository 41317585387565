@mixin defaultButton {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.auth {
  height: 2.125rem;
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.31;
  letter-spacing: -0.03em;
  color: var(--text);
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--border-auth);

  svg {
    width: 0.8125rem;
    height: 1rem;
  }

  path {
    fill: var(--text);
  }

  iframe {
    display: none;
  }
}

.avatarBox {
  padding: 0;
  display: flex;
  align-items: center;
  @include defaultButton;
}

.avatar {
  background-color: var(--avatar);
  width: 2.125rem;
  height: 2.125rem;
  overflow: hidden;

  &.withPhoto {
    background-color: transparent;
  }
}

.icon {
  margin-left: 0.5625rem;
  transform: rotate(0);
  transition: transform 0.2s ease;

  path {
    stroke: var(--text);
  }

  &.open {
    transform: rotate(180deg);
  }
}

.user {
  position: relative;

  :global(.accordion__container) {
    position: absolute;
    top: calc(100% + 0.37rem);
    right: 0;
    width: 14.0625rem;
    overflow: hidden;
    pointer-events: auto;
    border: 1px solid var(--black_t04);
    background-color: var(--dropdown-bg);
    box-shadow: 0 2.5rem 2.5rem 0 var(--black_t1),
      0 0 0.875rem 0 var(--black_t3);

    z-index: 1;
  }

  :global(.accordion__container--hidden) {
    height: 0;
    pointer-events: none;
    border-color: transparent;
    box-shadow: none;
    transition: box-shadow 0s 0.25s;
    opacity: 0;
  }
}

.card {
  background-color: var(--auth);
}

.firstLine {
  padding: 1.5625rem;
  background-color: var(--auth);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid var(--auth-border-transparent);
}

.name {
  margin: 0;
  max-width: 9.375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.00625em;
  color: var(--grey-f3);
}

.text {
  margin: 0;

  font-size: 0.625rem;
  color: var(--auth-text);
  letter-spacing: -0.0166em;
  line-height: 1.4;
}

.secondLine {
  padding: 1.25rem 1.5625rem;
  background-color: var(--auth-bg);
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.link {
  display: block;

  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: -0.00625em;
  color: var(--grey-f3);
}

.delimeter {
  width: 100%;
  height: 1px;
  background-color: var(--auth-border);
}

.exit {
  padding: 0;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: -0.00625em;
  color: var(--grey-f3);
  width: 100%;
  text-align: left;
  @include defaultButton;
}

.authMobileContainer {
  width: calc(50% + 3.6875rem);
  padding: 1.25rem 0 2.5rem 0;
  margin-left: 1rem;
  border-top: 0.0625rem solid var(--neutralLight);

  iframe {
    display: none;
  }
}

.authMobile {
  padding: 0;
  margin-left: auto;
  display: block;
  @include defaultButton;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.005em;
  color: var(--text);

  svg {
    width: 0.8125rem;
    height: 0.875rem;
    margin-right: 6px;
  }

  path {
    fill: var(--text);
  }
}

.userMobile {
  width: 100%;
  padding: 4.625rem 1rem 2.5rem;
}

.userMobileContent {
  padding-top: 1.25rem;
  display: flex;
  height: 100%;
  border-top: 0.0625rem solid var(--neutralLight);
}

.userInfo {
  width: calc(50% + 4.6875rem);
  text-align: end;
}

@media (min-width: 61rem) {
  .avatarBox {
    .name {
      display: none;
    }
  }
}

@media (max-width: 60rem) {
  .user {
    width: 50%;

    :global(.accordion__container) {
      width: calc(100vw - 2rem);
      top: calc(100% + 0.9rem);
    }
  }
  .avatarBox {
    width: 100%;
    background-color: var(--border);
    padding: 0.75rem 0.9375rem;
    gap: 0.625rem;
    justify-content: space-between;

    path {
      fill: var(--white);
    }

    .name {
      display: initial;
    }
  }

  .avatar {
    max-width: none;
    width: 100%;
    height: 3.375rem;
    margin-left: 1.625rem;

    margin-left: 0;

    width: 1.875rem;
    height: 1.875rem;
  }

  .firstLine {
    padding: 1.3rem 2.5rem 0rem 1.5rem;

    .name {
      display: none;
    }
  }

  .secondLine {
    gap: 1rem;
    padding-top: 1.5rem;
  }

  .text {
    padding: 0;
    margin: 0.1875rem 0 1.25rem;
    border: none;
  }

  .link {
    margin: 0;
    font-size: 0.9rem;
    line-height: 0.9rem;
    padding-bottom: 0.4rem;
  }

  .exit {
    text-align: left;
    font-size: 0.9rem;
    line-height: 0.9rem;
    padding-top: 0.5rem;
  }
}
