@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: url('/fonts/Inter-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: url('/fonts/Inter-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: url('/fonts/Inter-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: url('/fonts/Inter-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'JetBrains Mono';
  src: url('/fonts/JetBrainsMono-SemiBold.woff2') format('woff2');
  font-weight: 600; /* Bold weight */
  font-style: normal;
}

:root {
  --black: #000;
  --black-0a: #0a0a0a;
  --black-1a: #1a1a1a;
  --black-1e: #1e1e1e;
  --black-1e-t8: rgba(30, 30, 30, 0.8);
  --black-1e-t6: rgba(30, 30, 30, 0.6);
  --black-1e-t5: rgba(30, 30, 30, 0.5);
  --black-1e-t3: rgba(30, 30, 30, 0.3);
  --black-1e-t1: rgba(30, 30, 30, 0.1);
  --black_t02: rgba(0, 0, 0, 0.02);
  --black_t1: rgba(0, 0, 0, 0.1);
  --black_t3: rgba(0, 0, 0, 0.3);
  --black_t25: rgba(0, 0, 0, 0.25);
  --grey-a6: #a6a6a6;
  --grey-bf: #bfbfbf;
  --grey-f0: #f0f1f5;
  --grey-f2: #f2f2f2;
  --grey-f3: #f3f3f3;
  --grey-f6: #f6f6f6;
  --gray-eb: #ebedf1;
  --grey-e2: #e2e2e2;
  --grey-e7: #e7e7e7;
  --grey-e8: #e8e8e8;
  --grey-a: #aaa;
  --grey-74: #747474;
  --grey-93: #939393;
  --grey-f8: #f8f8f8;
  --grey-da: #dadfe5;
  --grey-dadada: #dadada;
  --grey-dadada-t8: rgb(218, 218, 218, 0.8);
  --grey-31: #313131;
  --grey-32: #323740;
  --grey-45: #454951;
  --grey-66: #667180;
  --white: #ffffff;
  --white-t1: rgba(255, 255, 255, 0.1);
  --white-t2: rgba(255, 255, 255, 0.2);
  --white-t3: rgba(255, 255, 255, 0.3);
  --white-t4: rgba(255, 255, 255, 0.4);
  --white-t5: rgba(255, 255, 255, 0.5);
  --white-t8: rgba(255, 255, 255, 0.8);
  --white-fe: #fefefe;
  --grey-d9: #d9d9d9;
  --grey-ef: #efefef;
  --blue: #1aa0dd;
  --blue-2b: #2babee;
  --blue-3e: #3ea9e2;
  --blue-00b: #00b3f8;
  --blue-007: #0077ff;
  --blue-16: #161c26;
  --blue-1e: #1e2735;
  --blue-2d: #2d3540;
  --maxWidth: 81.25rem;
  --gap: min(50px, 3.4722vw);
  --gapVertical: min(40px, 2.7777vw);
  --green-3a: #3a9f4f;
  --green-52: #52ac64;
  --green-e8: #e8fe82;
  --yellow-f4: #f4bb40;
  --yellow-ff: #ffcc14;
  --red-dc: #dc422e;
  --red-da: #da2912;
  --black-t8: rgba(30, 30, 30, 0.8);
  --red-ff: #ff0000;
  --red-fc: #fc3f1d;
}

body {
  --contrast: var(--black);
  --contrast-revert: var(--white);
  --contrast-t1: var(--black_t1);
  --contrast-t3: var(--black_t3);
  --text: var(--black-1e);
  --background: var(--white);
  --background-advert: var(--grey-f8);
  --background-sec: var(--white-fe);
  --dashed: var(--black-1e-t8);
  --vacancy: var(--grey-f3);
  --dropdown: var(--black-1e);
  --auth: var(--black-0a);
  --auth-bg: var(--black-1e);
  --auth-border: var(--white);
  --auth-border-transparent: transparent;
  --auth-text: var(--grey-f3);
  --aside-bg: var(--white);
  --aside: var(--grey-f3);
  --line-border: var(--black-1e-t5);
  --link-transparent: transparent;
  --line-short-article: var(--black);
  --breadcrumbs: var(--black-1e-t5);
  --reaction: var(--grey-d9);
  --detail-border: var(--grey-da);
  --avatar: var(--gray-eb);
  --grade-transparent: transparent;
  --advert: var(--grey-f8);
  --giga-border: var(--grey-e7);
  --search: var(--black-1e);
  --tg-banner: var(--blue-3e);
  --footer-border: transparent;
  --border: var(--black-1e);
  --news-border: var(--black-1e-t5);
  --selection: var(--grey-f3);
  --aside-number: var(--white-t1);
  --advert-giga-chat-text: var(--grey-93);
}

body.dark {
  --contrast: var(--white);
  --contrast-revert: var(--black);
  --contrast-t1: var(--white-t1);
  --contrast-t3: var(--white-t3);
  --text: var(--white);
  --background: var(--blue-16);
  --background-sec: var(--blue-16);
  --background-advert: var(--blue-2d);
  --dashed: var(--grey-66);
  --vacancy: var(--blue-1e);
  --dropdown: var(--blue-1e);
  --auth: var(--blue-1e);
  --auth-bg: var(--blue-1e);
  --auth-border: var(--white-t2);
  --auth-border-transparent: var(--grey-dadada);
  --auth-text: var(--grey-dadada);
  --aside-bg: var(--blue-16);
  --aside: var(--blue-1e);
  --line-border: var(--white-t5);
  --link-transparent: var(--blue-1e);
  --line-short-article: var(--grey-66);
  --breadcrumbs: var(--white-t4);
  --reaction: var(--grey-45);
  --detail-border: var(--white-t2);
  --avatar: var(--grey-45);
  --grade-transparent: var(--blue-1e);
  --advert: var(--blue-1e);
  --giga-border: var(--grey-66);
  --search: var(--blue-2d);
  --tg-banner: var(--white);
  --footer-border: var(--white-t2);
  --border: var(--grey-66);
  --news-border: var(--grey-66);
  --selection: var(--blue-3e);
  --aside-number: var(--black-1e-t1);
  --advert-giga-chat-text: var(--grey-93);
}

body.dark .kandinsky-logo {
  img {
    filter: invert(1);
  }
}

html {
  box-sizing: border-box;
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

//fix for safari bottom subpixels
body {
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    outline: 1px solid var(--blue-16);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: var(--background-sec);
  color: var(--text);
}

*::selection {
  background: var(--selection);
}

button:focus,
input:focus,
select:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: var(--text);
}

.jb {
  font-family: 'JetBrains Mono', Helvetica, Arial, sans-serif;
}

.bold {
  font-weight: bold;
}

.semibold {
  font-weight: 600;
}

.normal {
  font-weight: normal;
}

.highlight {
  color: var(--blue);
}

h1,
.h1 {
  font-size: 32px;
  line-height: 1.1;
  margin: 0.75em 0;
  letter-spacing: -0.01em;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 1.15;
  letter-spacing: -0.7px;
}

h3,
.h3,
h4,
.h4 {
  font-size: 20px;
  line-height: 1.4;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 1.22;
}

h6,
.h6 {
  font-size: inherit;
  line-height: inherit;
  color: var(--text);
}

.text-s {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: -0.2px;
}

.text-xs {
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: -0.2px;
}

.text-l {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.02em;
}

.text-m {
  font-size: 14px;
  line-height: 1.33;
  letter-spacing: -0.02em;
}

.big-title {
  font-size: 3.625rem;
}

.long-mobile-title {
  font-size: 3rem;
}

.newsDetail-title {
  font-size: 2.25rem;
  line-height: 1.24;
}

.content {
  ul,
  ol,
  p {
    margin: 24px 0;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  p:first-child {
    margin-top: 0;
  }

  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.fslightbox-source {
  display: block;
  max-width: 100%;
  max-height: 100vh;
  height: auto !important;
  width: auto;
}

@keyframes responsive-modal-modal-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes responsive-modal-modal-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.content a,
.link-hover-default,
.link-hover {
  border-bottom: 1px solid transparent;
  transition: 0.2s ease-in-out;

  &:hover {
    border-bottom-color: inherit;
  }
}

.link-hover-default {
  border-bottom-color: inherit;
}

h1,
.h1,
.big-heading,
h2,
.h2,
h3,
.h3,
h5,
.h5 {
  font-weight: 500;
}

h1,
.h1 {
  font-size: 36px;
  line-height: 1.195;
  letter-spacing: -0.03em;
}

.big-heading {
  font-size: 3.625rem;
  line-height: 1.06;
  letter-spacing: -0.05em;
}

.banner-heading {
  font-size: 26px;
  line-height: 1.34;
  letter-spacing: -0.05em;
  font-weight: 500;
}

h2,
.h2 {
  font-size: 24px;
}

h3,
.h3 {
  font-size: 20px;
  letter-spacing: -0.03em;
}

h4,
.h4 {
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.02em;
}

h5,
.h5 {
  font-size: 14px;
  line-height: 1.3125;
}

body {
  font-size: 16px;
  line-height: 1.66;
}

.small {
  font-size: 12px;
}

.specialHeading {
  font-size: 30px;
}

.specialHeadingBig {
  font-size: 30px;
}

.specialSubtitle {
  font-size: 20px;
}

.gigaChatStoriesText {
  font-size: 14px;
}

.gigaChatStoriesTextPromo {
  font-size: 10px !important;
}

.gigaChatStoriesTitle {
  font-size: 17px;
}

@media screen and (min-width: 375px) {
  h1,
  .h1 {
    font-size: calc(36px + 10 * ((100vw - 375px) / 625));
  }

  .big-heading {
    font-size: calc(58px + 40 * ((100vw - 375px) / 625));
  }

  .banner-heading {
    font-size: calc(26px + 34 * ((100vw - 375px) / 625));
  }

  h2,
  .h2 {
    font-size: calc(24px + 6 * ((100vw - 375px) / 625));
  }

  h3,
  .h3 {
    font-size: calc(20px + 6 * ((100vw - 375px) / 625));
  }

  h4,
  .h4 {
    font-size: calc(11px + 2 * ((100vw - 375px) / 625));
  }

  h5,
  .h5 {
    font-size: calc(14px + 2 * ((100vw - 375px) / 625));
  }

  body {
    font-size: calc(16px + 2 * ((100vw - 375px) / 625));
  }

  .small {
    font-size: calc(12px + 2 * ((100vw - 375px) / 625));
  }

  .text-l {
    font-size: calc(24px + 6 * ((100vw - 375px) / 625));
  }

  .text-m {
    font-size: calc(14px + 6 * ((100vw - 375px) / 625));
  }

  .big-title {
    font-size: calc(58px + 42 * ((100vw - 375px) / 625));
  }
  .long-mobile-title {
    font-size: calc(48px + 52 * ((100vw - 375px) / 625));
  }
  .newsDetail-title {
    font-size: calc(36px + 10 * ((100vw - 375px) / 625));
  }

  .specialHeading {
    font-size: calc(30px + 20 * ((100vw - 375px) / 625));
  }

  .specialHeadingBig {
    font-size: calc(30px + 30 * ((100vw - 375px) / 625));
  }

  .specialSubtitle {
    font-size: calc(20px + 6 * ((100vw - 375px) / 625));
  }

  .gigaChatStoriesText {
    font-size: calc(14px + 10 * ((100vw - 375px) / 625));
  }

  .gigaChatStoriesTitle {
    font-size: calc(17px + 7 * ((100vw - 375px) / 625));
  }
}

@media screen and (min-width: 375px) {
  .gigaChatStoriesTextPromo {
    font-size: calc(10px + 6 * ((100vw - 375px) / 925)) !important;
  }
}

@media screen and (min-width: 1300px) {
  .gigaChatStoriesTextPromo {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1000px) {
  h1,
  .h1 {
    font-size: 46px;
  }

  .big-heading {
    font-size: 100px;
  }

  .banner-heading {
    font-size: 60px;
  }

  h2,
  .h2 {
    font-size: 30px;
  }

  h3,
  .h3 {
    font-size: 26px;
  }

  h4,
  .h4 {
    font-size: 13px;
  }

  h5,
  .h5 {
    font-size: 16px;
  }

  h6,
  .h6 {
    font-size: inherit;
    line-height: inherit;
  }

  .text-l {
    font-size: 30px;
  }

  .text-m {
    font-size: 20px;
  }

  .long-mobile-title {
    font-size: 6.25rem;
  }

  .big-title {
    font-size: 6.25rem;
  }

  .newsDetail-title {
    font-size: 2.875rem;
  }

  .specialHeading {
    font-size: 50px;
  }

  .specialHeadingBig {
    font-size: 60px;
  }

  .specialSubtitle {
    font-size: 26px;
  }

  body {
    font-size: 18px;
  }

  .small {
    font-size: 14px;
  }
  .gigaChatStoriesText {
    font-size: 24px;
  }

  .gigaChatStoriesTitle {
    font-size: 24px;
  }
}

.fslightbox-source {
  display: block;
  max-width: 100%;
  max-height: 100vh;
  height: auto !important;
  width: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.ls-03 {
  letter-spacing: -0.03em;
}

.ls-02 {
  letter-spacing: -0.02em;
}

.ls-01 {
  letter-spacing: -0.01em;
}

.grecaptcha-badge {
  display: none;
}
