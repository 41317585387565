.container {
  position: relative;
  flex-grow: 1;
  cursor: pointer;

  .arrow {
    margin-left: 0.625rem;
    transition: transform 0.3s ease;
    vertical-align: middle;
  }

  &:hover {
    .arrow {
      transform: rotate(180deg);
    }

    .item .name {
      border-bottom-color: inherit;
    }
  }

  &:not(.mobile, .footer) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 15.38%;

    &:not(:first-child) {
      .name {
        margin-left: 1.562rem;
      }

      &:not(:first-child):not(:last-child) {
        width: 17.31%;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        height: 1.375rem;
        width: 0.25rem;
        background: var(--border);
        display: block;
        transform: translate3d(-50%, 0, 0);
        content: '';
      }
    }
  }

  .item {
    font-size: 1.5rem;
    line-height: 1.2;

    .name {
      font-weight: 500;
      letter-spacing: -0.03em;
      transition: 0.2s ease-in-out;
      border-bottom: 2px solid transparent;
    }
  }
}

.total {
  position: relative;
  display: inline-block;
  padding-left: 0.25em;
  font-weight: 300;
  font-size: 50%;
  top: -0.75em;
  letter-spacing: -0.01em;
}

.specialTag {
  text-transform: uppercase;

  svg {
    margin-left: 0.25rem;
    animation: specialTagIconAnimation 1.5s linear infinite;
  }
}

@media (max-width: 70rem) {
  .item {
    .name {
      font-size: 1.25rem;
    }

    svg {
      width: 0.75rem;
    }
  }

  .specialTag svg {
    width: auto;
  }
}

.footer span {
  color: var(--white);
}

@keyframes specialTagIconAnimation {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }
}
