.rates {

}

.slider {
  height: 1.25rem;
}

.date {
  font-weight: 600;
  font-size: 0.81rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.slide {
  height: 1.25rem !important;
  white-space: nowrap;
}
