.wrapper {
  display: none;
}

@media (max-width: 62rem) {
  .wrapper {
    display: block;
    background-color: var(--background-sec);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-in;

    &--hidden {
      transform: translate3d(0, -6.875rem, 0);
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.75rem;
  }

  .logo {
    padding: 1rem;
    display: flex;
    align-items: center;
  }

  .search,
  .burger {
    border: none;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    color: var(--text);
  }

  .search {
    margin-left: -1rem;
  }

  .burger {
    margin-right: -1rem;
  }
}
