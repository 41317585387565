.wrapper {
  background: var(--background-sec);
  padding: 2.5rem 0;
}

.searchBarWrapper {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
}

.buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 1rem;

  .button {
    width: 100%;
    height: 3.44rem;
    background-color: var(--vacancy);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;

    font-weight: 500;
    font-size: 0.88rem;
    line-height: 129%;
    letter-spacing: -0.01em;
    color: var(--text);
  }
}

.tag {
  font-weight: 600;
  font-size: 0.69rem;
  text-transform: uppercase;
  color: var(--text);
  margin: 1.5rem 0 0;
}

.modal {
  flex-grow: 1;
  // overflow-y: auto;
  height: 100vh;
  padding: 0;
  margin-top: 3.75rem;
}

@supports (height: 100dvh) {
  .element {
    height: 100dvh;
  }
}

.soc {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2.1rem 0 1.875rem;
  padding: 1rem 0 0;
  align-items: center;
  border-top: 1px solid var(--border);

  svg {
    width: 3.125rem;
    height: 3.125rem;
  }

  a {
    display: flex;
    border-radius: 50%;
    background-color: var(--link-transparent);
  }
}

.copyright {
  padding: 1rem 0;
  margin: 0;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: -0.02em;
}

@media (max-width: 62rem) {
  .buttons {
    margin-top: 0;
  }
}
