.searchBar {
  background-color: var(--search);
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 102;
}

.showSearchBar {
  transition: transform 0.5s;
}

.hideSearchBar {
  transform: translateY(-10rem);
  transition: transform 0.3s;
}

.searchBar__container {
  margin-left: calc((100% - 111rem) / 2);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchBar__buttonSearch {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--white);
}

.searchIcon {
  transform: translateY(0.125rem);
}

.searchBar__input {
  background-color: var(--search);
  border: none;
  color: var(--white);
  padding: 0.8125rem;
  width: 100%;
}

.searchBar__field {
  background-color: var(--search);
  border: none;
  color: var(--white);
  font-size: 0.875rem;
  padding: 0.625rem 0.625rem 0.625rem 0;
  width: 100%;
  letter-spacing: -0.01em;
}

.searchBar__field:focus {
  outline: none;
}

.searchBar__field::placeholder {
  color: var(--white);
  opacity: 0.5;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0em;
}

.searchBar__button {
  background-color: var(--search);
  border: none;
  color: var(--white);
  font-size: 1.2rem;
  padding: 0;
  margin: 0.5rem 1.3125rem 0 auto;
}

.searchBar__button:hover {
  cursor: pointer;
}

@media (max-width: 116.25rem) {
  .searchBar__container {
    margin-left: 1.2rem;
  }
}

@media (max-width: 90rem) {
  .searchBar__container {
    margin-left: 1.2rem;
  }
}

@media (max-width: 62rem) {
  .searchBar__container {
    margin-left: 1rem;
  }
  .searchBar {
    height: 4rem;
  }
}
