.lineImage {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  margin-right: 0.75rem;
  border-radius: 2px;
  object-fit: cover;
}

.time {
  margin: 0 0.75em 0 0;
  font-weight: 300;
}

.title {
  margin: 0;
  font-weight: 600;
}

.lineWrapper {
  width: 100%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  height: 30px;
}

.line {
  display: inline-flex;
  will-change: transform;
  animation: move-items 100s linear infinite;
}

.lineList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.lineList a {
  display: flex;
  align-items: center;

  &:after {
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    background: var(--text);
    margin-left: 1.5625rem;
    margin-right: 1.875rem;
    content: '';
  }
}

@keyframes move-items {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
