.root {
  transition: 0.2s ease-in-out;
  border: none;
  position: fixed;
  top: 0;
  width: calc(100vw);
  padding-bottom: 0;
  padding: 0;
}

.rootClosing {
  opacity: 0;
}
